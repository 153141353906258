@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Inter', sans-serif
}
#font-inter{
  font-family: 'Inter', sans-serif;
  ;
}
#font-livvic{
  font-family: 'Livvic', sans-serif;
}
#font-roboto{
  font-family: 'Roboto Medium', sans-serif;
}
#font-gliker{
  font-family:'Gliker';
}
details summary::-webkit-details-marker{
  list-style:none;
  display:none;
  color: blue;
  align-item:start;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}
@font-face{
  font-family:'Gliker';
  src:url('Gliker-Regular/Gliker-Regular.ttf') format('truetype')
}
@media screen and (max-width:806px) and (min-width:768px){
  .navbarwidth{
    padding-left:75px;
  }
  .jcommunity{
    padding-top:32rem;
  }
  .seemless{
    padding-top:5rem;
  }
  .seemless_boarder img{
    width:70%;
    position:absolute;
    bottom:0;
    right:0;
  }
  details{
    display:hidden;
  }
}